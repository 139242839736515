<template>
  <!-- Favorite levels -->
  <template v-if="favoriteLvls && favoriteLvls.length && favoritesActive">
    <ul
      v-if="favoriteLvls && favoriteLvls.length && favoritesActive"
      class="clebex-item-section pill"
    >
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          <div class="label">
            {{ displayLabelName("level", "level", "favorite-levels") }}
          </div>
          <span class="follow-up-icons">
            <button class="follow-up-icon-item" @click="openLevels">
              <icon v-if="favoritesActive" icon="#cx-app1-favourite-fill" />
              <icon v-else icon="#cx-app1-favourite" />
            </button>
          </span>
        </div>
      </li>
      <li
        class="clebex-item-section-item favorite-level"
        v-for="(level, i) in favoriteLvls"
        :key="i"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <button class="follow-up-icon-item" @click="setData(level.id)">
              <icon
                v-if="selectedLevel === level.id"
                icon="#cx-app1-favourite-default"
              />
              <icon
                v-else-if="level.is_favorite"
                icon="#cx-app1-favourite-fill"
              />
              <icon v-else icon="#cx-app1-favourite" />
            </button>
          </span>
          <dl class="clebex-item-dl">
            <dt class="clebex-item-dt">
              <div
                class="clebex-item-parent-list"
                v-if="level.all_parents && level.all_parents.length"
              >
                <p
                  class="parent-name"
                  v-for="parent in level.all_parents"
                  :key="parent.id"
                >
                  {{ parent.name }}
                </p>
              </div>
            </dt>
            <dd class="clebex-item-dd">
              <span>{{ level.name }}</span>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
  </template>
  <!-- Favorite levels -->

  <template v-else>
    <ul class="clebex-item-section pill">
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          <div class="label">
            {{ displayLabelName("level", "level", "levels") }}
          </div>
          <span class="follow-up-icons">
            <button @click="openFavorites" class="follow-up-icon-item">
              <icon icon="#cx-app1-favourite" />
            </button>
          </span>
        </div>
      </li>
      <template v-if="selectedLevels && selectedLevels.length">
        <li
          class="clebex-item-section-item"
          v-for="(level, i) in selectedLevels"
          :key="i"
        >
          <div @click="getSublevels(level)" class="clebex-item-content-wrapper">
            <span class="follow-up-icons">
              <button
                class="follow-up-icon-item"
                @click.stop="setData(level.id)"
              >
                <icon
                  v-if="selectedLevel === level.id"
                  icon="#cx-app1-favourite-default"
                />
                <icon
                  v-else-if="level.is_favorite"
                  icon="#cx-app1-favourite-fill"
                />
                <icon v-else icon="#cx-app1-favourite" />
              </button>
            </span>
            <span class="label">
              <span class="highlight">
                {{ level.name }}
              </span>
            </span>
            <span
              v-if="listOfLevels && listOfLevels.length"
              class="follow-up-icons"
              @click="getSublevels(level, true)"
            >
              <svg-icon icon="outside"></svg-icon>
            </span>
          </div>
        </li>
      </template>
    </ul>
    <template v-if="listOfLevels && listOfLevels.length">
      <ul class="clebex-item-section pill">
        <li class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            {{ displayLabelName("level", "level", "sub-levels") }}
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-for="lvl in listOfLevels"
          :key="lvl.id"
        >
          <button
            class="clebex-item-content-wrapper"
            @click="getSublevels(lvl)"
          >
            <span class="follow-up-icons">
              <button class="follow-up-icon-item" @click.stop="setData(lvl.id)">
                <icon
                  v-if="selectedLevel === lvl.id"
                  icon="#cx-app1-favourite-default"
                />
                <icon
                  v-else-if="lvl.is_favorite"
                  icon="#cx-app1-favourite-fill"
                />
                <icon v-else icon="#cx-app1-favourite" />
              </button>
            </span>
            <span class="label">
              <span class="highlight">
                {{ lvl.name }}
              </span>
            </span>
            <span class="follow-up-icons">
              <button class="follow-up-icon-item">
                <icon icon="#cx-app1-information" />
              </button>
            </span>
          </button>
        </li>
      </ul>
    </template>
  </template>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "DefaultLocation",
  data() {
    return {
      selectedLevel: null,
      listOfLevels: null,
      selectedLevels: [],
      favoritesActive: null
    };
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"]),
    ...mapState("level", ["favoriteLevels", "levels"]),
    favoriteLvls() {
      return this.favoriteLevels && this.favoriteLevels.data
        ? this.favoriteLevels.data
        : [];
    }
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  watch: {
    levels: {
      handler() {
        this.mapSubLevelProperties();
      }
    },
    listOfLevels: {
      handler() {
        this.mapSubLevelProperties();
      }
    }
  },
  created() {
    this.getLevels(true);
    if (
      this.preferences[0].preference_values.length &&
      this.preferences[0].preference_values[0].value
    ) {
      this.selectedLevel = parseInt(
        this.preferences[0].preference_values[0].value
      );
    }
  },
  methods: {
    ...mapActions("companyPreferences", [
      "setPreferenceUpdateData",
      "setPreferences"
    ]),
    ...mapActions("level", [
      "favoriteLevel",
      "unFavoriteLevel",
      "resetLevelsState"
    ]),
    ...mapActions("level", {
      getFavoriteLevels: "getLevels",
      getSubLvls: "getSublevels"
    }),
    mapSubLevelProperties() {
      if (this.listOfLevels && this.levels && this.levels.data) {
        this.listOfLevels.forEach(l => {
          const sameSubLevel = this.levels.data.find(k => l.id === k.id);
          if (sameSubLevel) {
            l.is_favorite = sameSubLevel.is_favorite;
          }
        });
      }
    },
    getLevels(initial) {
      this.selectedLevels = [];
      const params = {
        depth: "TOP"
      };
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/all`, { params })
        .then(response => {
          if (initial) {
            this.listOfLevels = [];
            this.selectedLevels = response.data.data;
          } else {
            this.listOfLevels = response.data.data;
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    getSublevels(level, getUp) {
      if (!getUp) {
        this.selectedLevels = [];
        this.selectedLevels.push(level);
      }
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.getSubLvls(level.id);
      httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${level.id}`, {
          params: {
            includes: ["all_parents", "children"]
          }
        })
        .then(response => {
          if (getUp) {
            if (!response.data.data.all_parents.length) {
              this.getLevels(true);
            } else {
              this.selectedLevels.splice(
                this.selectedLevels.findIndex(function(item) {
                  return item.id === level.id;
                }),
                1
              );
              this.selectedLevels.splice(
                this.selectedLevels.findIndex(function(item) {
                  return item.id === response.data.data.all_parents[0].id;
                }),
                1
              );
              this.getSublevels(response.data.data.all_parents[0]);
            }
          } else {
            this.listOfLevels = response.data.data.children;
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setData(levelId) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      let values = [];
      if (this.selectedLevel === levelId) {
        levelId = null;
      } else {
        values = [
          {
            value: levelId
          }
        ];
      }
      this.selectedLevel = levelId;
      const newPreferenceUpdateData = {
        values: values
      };
      if (
        this.preferenceUpdateData.values[0] &&
        this.preferenceUpdateData.values[0].id &&
        values.length
      ) {
        newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
      }
      this.setPreferenceUpdateData(newPreferenceUpdateData);
      httpServiceAuth
        .put(
          `${apiEndpoints.company.companyPreferences}/${this.preferenceId}`,
          this.preferenceUpdateData
        )
        .then(response => {
          const preferencesUpdateData = [...this.preferences];
          const objIndex = preferencesUpdateData.findIndex(
            obj => obj.id == response.data.data.id
          );
          preferencesUpdateData[objIndex] = response.data.data;
          this.setPreferences(preferencesUpdateData);
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.$router.push({ name: "r_company-preference-category" });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        });
    },
    openFavorites() {
      this.getFavoriteLevels({
        depth: "ALL",
        favorites: 1,
        includes: ["all_parents", "default"]
      });
      this.selectedLevels = [];
      this.favoritesActive = true;
    },
    openLevels() {
      this.selectedLevels = [];
      this.favoritesActive = false;
      this.getLevels();
    }
  },
  beforeUnmount() {
    this.resetLevelsState();
  }
};
</script>
